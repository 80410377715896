import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import parkingRoutes from './modules/parking';
import boardRoutes from './modules/board';
import faqRoutes from './modules/faq';
import managerRoutes from './modules/manager';
import mypageRoutes from './modules/mypage';
import nonstopRoutes from './modules/nonstop';
import periodRoutes from './modules/period';
import periodApronRoutes from './modules/periodApron';
import prepaymentRoutes from './modules/prepayment';
import reductionRoutes from './modules/reduction';
import systemRoutes from './modules/system';
import unpaidRoutes from './modules/unpaid';
import utilRoutes from './modules/util';
import webDiscountRoutes from './modules/webDiscount';

import Error from '@/html/subpage/util/Error.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/main',
  },
  // 메인 페이지
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "view-main-vue" */ '@/html/mainpage/main'),
    meta: { category: ['메인', '메인'] },
  },
  ...parkingRoutes,
  ...boardRoutes,
  ...faqRoutes,
  ...managerRoutes,
  ...mypageRoutes,
  ...nonstopRoutes,
  ...periodRoutes,
  ...periodApronRoutes,
  ...prepaymentRoutes,
  ...reductionRoutes,
  ...systemRoutes,
  ...unpaidRoutes,
  ...utilRoutes,
  ...webDiscountRoutes,
  // Not Found Page
  {
    path: '/404',
    name: 'notFound',
    component: Error,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 },
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const isLogin = store.state.isLogin;
  const authorCode = store.state.authorCode;
  const empDiv = store.state.empDiv;
  const { authorization, empcategory } = to.meta;

  if (to.matched.some(record => record.meta.authorization)) {
    if (authorCode === 'ROLE_ADMIN') {
      next();
      return;
    }
  }

  if (to.matched.some(record => record.meta.authorization)) {
    if (!isLogin) {
      alert('로그인이 필요한 서비스입니다.');
      next('/login');
      return;
    }

    if (!authorization.includes(authorCode)) {
      alert('접근 권한이 존재하지 않습니다.');
      next('/main');
      return;
    }
  }

  if (to.matched.some(record => record.meta.empcategory)) {
    if (!empcategory.includes(empDiv)) {
      alert('접근 권한이 존재하지 않습니다.');
      next('/main');
      return;
    }
  }

  if (to.matched.some(record => record.meta.onlyGuest)) {
    if (isLogin) {
      return next({ path: '/main' });
    }
  }

  next();
});
