function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/periodapron/${view}.vue`);
}

const periodapronRoutes = [
  /* 정기권서비스 */

  // 1. 정기권 안내
  {
    path: '/periodaproninfo',
    name: 'periodaproninfo',
    component: loadView('PeriodApronInfo'),
    meta: {
      category: ['주기장 정기권', '주기장 정기권'],
    },
  },
  // 2. 정기권 개인정보 동의
  {
    path: '/periodapronagreement',
    name: 'periodapronagreement',
    component: loadView('PeriodApronAgreement'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2. 정기권 신청 기본 정보입력
  {
    path: '/periodapronapplication',
    name: '/periodapronapplication',
    component: loadView('PeriodApronApplication'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2-1. 정기권 신청 정보 입력
  {
    path: '/periodaproninputinfo',
    name: '/periodaproninputinfo',
    component: loadView('PeriodApronInputInfo'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2-2. 정기권 신청
  {
    path: '/periodapronapply',
    name: '/periodapronapply',
    component: loadView('PeriodApronApply'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 3. 정기권 조회
  {
    path: '/periodapronlookup',
    name: 'periodapronlookup',
    component: loadView('PeriodApronLookup'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 조회/결제'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 4. 졍기권 상세 및 결제
  {
    path: '/periodapronview',
    name: 'periodapronview',
    component: loadView('PeriodApronView'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 조회/결제'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 4. 졍기권 재직증명 변경 상세
  {
    path: '/periodaprontransferview',
    name: 'periodaprontransferview',
    component: loadView('PeriodApronTransferView'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 조회'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 5. 정기권 해지
  {
    path: '/periodaproncancel',
    name: 'periodaproncancel',
    component: loadView('PeriodApronCancel'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 해지'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 6. 정기권 해지 상세
  {
    path: '/periodaproncancelview',
    name: 'periodaproncancelview',
    component: loadView('PeriodApronCancelView'),
    props: true,
    meta: {
      category: ['주기장 정기권', '정기권 해지'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
];

export default periodapronRoutes;
